<template>
  <v-container
    style="max-width: 1200px"
    >
    <Header 
      title="Métodos de envío"
      >
      <v-spacer>
      </v-spacer>

      <create
        ></create>
    </Header>

    <v-divider
      class="my-2"
      ></v-divider>

    <div
      class="my-2 text-subtitle-1 text-center"
      >
      Aquí es posible habilitar o deshabilitar las empresas logísticas en convenio con PartnersFans (casilla <span class="primary--text font-weight-medium">Activo</span>) y crear métodos de envío propios.
    </div>

    <template
      v-for="shippingMethod in shippingMethods"
      >
      <ShippingMethod
        :id="shippingMethod.id"
        />
    </template>
  </v-container>
</template>

<script>
import { ShippingMethods } from '@/graphql/queries/shipping_methods'
import Create from '@/components/admin/shipping_methods/Create'
import ShippingMethod from '@/components/admin/shipping_methods/ShippingMethod'
import Header from '@/components/admin/shared/Header'

export default {
  data () {
    return {
      shippingMethods: []
    }
  },

  mounted () {
    this.fetchShippingMethods()
  },

  components: {
    Header,
    ShippingMethod,
    Create
  },

  methods: {
    fetchShippingMethods () {
      this.$apollo.query({
        query: ShippingMethods,
      }).then( res => {
        this.shippingMethods = res.data.shippingMethods
      })
    }
  }
}
</script>
