<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    width="800"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        tile
        depressed
        color="primary"
        v-bind="attrs"
        v-on="on"
        >
        Crear
      </v-btn>
    </template>
    
    <v-card>
      <v-card-title
        class="text-subtitle-1"
        >
        Crear método de envío propio
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          color="primary"
          icon
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-text-field
            filled
            label="Nombre"
            v-model="shippingMethod.name"
            placeholder="Ej.: Starken, Chilexpress, Bluexpress, Transportes Lara, Vehículo Propio, etc."
            hint="Ej.: Starken, Chilexpress, Bluexpress, Transportes Lara, Vehículo Propio, etc."
            persistent-hint
            :rules="[
            v => !!v || 'Campo obligatorio'
            ]"
            >
          </v-text-field>

          <v-autocomplete
            filled
            :items="regions"
            label="Cobertura"
            v-model="shippingMethod.cover"
            hint="Seleccionar todas las comunas que apliquen, dejar vacío si es nacional."
            persistent-hint
            multiple
            chips
            small-chips
            deletable-chips
            >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 5">
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 5"
                class="grey--text text-caption"
                >
                (+{{ shippingMethod.cover.length - 5 }})
              </span>
            </template>
          </v-autocomplete>

          <v-row
            >
            <v-col
              cols="12"
              class="pb-0"
              >
              <div
                class="black--text"
                >
                <span class="font-weight-bold">IMPORTANTE</span>: En caso de seleccionar "PAGADO" deberá ingresar un monto de envío fijo que se cobrará al consumidor final al momento de realizar la compra. Al seleccionar "POR PAGAR" se compromete a subir un comprobante de envío al finalizar la compra donde se indique el monto a abonar en la agencia de destino.
              </div>
            </v-col>

            <v-col
              cols="12"
              md="6"
              >
              <v-btn-toggle
                class="fill-width"
                v-model="shippingMethod.toPay"
                color="primary"
                mandatory
                >
                <v-btn
                  class="py-5"
                  :value="true"
                  >
                  Por pagar
                </v-btn>

                <v-btn
                  class="py-5"
                  :value="false"
                  >
                  Pagado
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="!shippingMethod.toPay"
              >
              <v-text-field
                filled
                label="Precio"
                v-model="shippingMethod.price"
                prefix="$"
                type="number"
                hide-details="auto"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-divider
            class="my-3"
            ></v-divider>

          <div
            class="mb-1 mt-3 black--text font-weight-medium"
            >
            Términos y condiciones
          </div>

          <div
            class="mb-2 black--text caption"
            >
            Detalle de los despachos: en el día, quien paga el envío, las devoluciones, y todos aquellos aspectos que influyan a la hora de realizar el envío.
          </div>

          <wysiwyg 
            class="mb-3"
            v-model="shippingMethod.description"
            />

          <div
            style="gap: 12px"
            class="text-right d-flex justify-end"
            >
            <v-btn
              tile
              color="primary"
              @click="create"
              >
              Guardar
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Create } from '@/graphql/mutations/shipping_method'
import { Chile } from '@/utils/regions.js'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    regions: Chile,
    shippingMethod: {
      name: null,
      description: null,
      active: true,
      cover: []
    },
  }),

  methods: {
    create () {
      if (this.valid) this.valid = this.$refs.form.validate()

      if (this.valid) {
        this.$apollo.mutate({
          mutation: Create,
          variables: {
            input: {
              attributes: this.shippingMethod
            }
          }
        }).then( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
