import gql from 'graphql-tag'
import { ShippingMethod as SM } from '@/graphql/fragments'

export const ShippingMethods = gql`
  query shippingMethods {
    shippingMethods {
      ...${ SM }
    }
  } 
`

export const ShippingMethod = gql`
  query shippingMethod($id: ID!) {
    shippingMethod(id: $id) {
      ...${ SM }
      preferences
    }
  } 
`
