<template>
  <v-card
    v-if="shippingMethod"
    class="mb-3"
    >
    <v-card-title
      style="cursor: pointer"
      @click="expand = !expand"
      >
      {{ shippingMethod.name }}
      <v-spacer></v-spacer>
      <v-icon
        >
        mdi-chevron-{{ expand ? 'up' : 'down' }}
      </v-icon>
    </v-card-title>

    <v-expand-transition>
      <v-card-text
        v-if="expand"
        >
        <div
          v-if="shippingMethod"
          >
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
            >
            <template
              v-if="shippingMethod.name == 'DITUCI'"
              >
              <v-text-field
                filled
                label="Casilla de correos"
                v-model="shippingMethod.preferences.mail"
                :rules="emailRules"
                >
              </v-text-field>

              <v-text-field
                filled
                label="Comisión"
                type="number"
                suffix="%"
                v-model="shippingMethod.preferences.commission"
                :rules="requiredRules"
                >
              </v-text-field>

              <div
                class="text-right"
                >
                <v-btn
                  tile
                  color="primary"
                  @click="editShippingMethod"
                  >
                  Guardar
                </v-btn>
              </div>
            </template>

            <template
              v-if="shippingMethod.name == 'Sendcargo'"
              >
              <div
                class="text-subtitle-1"
                >
                Operador logístico para la RM, V y VI región con hora de corte 12.00 hrs. (toda compra hecha después de ese horario será considerada para enviar al día siguiente). 
                <br />
                El servicio consta de retiro en la dirección configurada y la entrega en domicilio al cliente por un costo y plazo de entrega detallado en tarifario.
                <br />
                Debe tener listos sus envíos imprimiendo su listado de retiros diario para la firma del transportista, imprimiendo y pegando sus etiquetas en cada uno de los productos embalados para enviar.
                <v-divider
                  class="my-3"
                  ></v-divider>
                Para ver el tarifario de envío con costos y tiempos de entrega, por favor ingresa <span @click="sendcargoPricingUrl" class="primary--text font-weight-bold" style="cursor: pointer">aquí</span>.
              </div>
            </template>

            <template
              v-if="!['DITUCI', 'Sendcargo'].includes(shippingMethod.name)"
              >
              <v-text-field
                filled
                label="Nombre"
                v-model="shippingMethod.name"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                >
              </v-text-field>

              <v-autocomplete
                filled
                :items="regions"
                label="Cobertura"
                v-model="shippingMethod.cover"
                hint="Seleccionar todas las comunas que apliquen, dejar vacío si es nacional."
                persistent-hint
                multiple
                chips
                small-chips
                deletable-chips
                >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 5">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span
                    v-if="index === 5"
                    class="grey--text text-caption"
                    >
                    (+{{ shippingMethod.cover.length - 5 }})
                  </span>
                </template>
              </v-autocomplete>


              <v-row
                >
                <v-col
                  cols="12"
                  class="pb-0"
                  >
                  <div
                    class="black--text"
                    >
                    <span class="font-weight-bold">IMPORTANTE</span>: En caso de seleccionar "PAGADO" deberá ingresar un monto de envío fijo que se cobrará al consumidor final al momento de realizar la compra. Al seleccionar "POR PAGAR" se compromete a subir un comprobante de envío al finalizar la compra donde se indique el monto a abonar en la agencia de destino.
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  >
                  <v-btn-toggle
                    class="fill-width"
                    v-model="shippingMethod.toPay"
                    color="primary"
                    mandatory
                    >
                    <v-btn
                      class="py-5"
                      :value="true"
                      >
                      Por pagar
                    </v-btn>

                    <v-btn
                      class="py-5"
                      :value="false"
                      >
                      Pagado
                    </v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="!shippingMethod.toPay"
                  >
                  <v-text-field
                    filled
                    label="Precio"
                    v-model="shippingMethod.price"
                    prefix="$"
                    type="number"
                    hide-details="auto"
                    :rules="[
                    v => !!v || 'Campo obligatorio'
                    ]"
                    ></v-text-field>
                </v-col>
              </v-row>

              <v-divider
                class="my-3"
                ></v-divider>

              <div
                class="mb-1 mt-3 black--text font-weight-medium"
                >
                Términos y condiciones
              </div>

              <div
                class="mb-2 black--text caption"
                >
                Detalle de los despachos: en el día, quien paga el envío, las devoluciones, y todos aquellos aspectos que influyan a la hora de realizar el envío.
              </div>

              <wysiwyg 
                class="mb-3"
                v-model="shippingMethod.description"
                />
            </template>

            <v-divider
              class="my-3"
              ></v-divider>

            <div>
              <div
                class="caption"
                >
                Al desactivar un método de envío el mismo no será visible al momento de finalizar una compra.
              </div>

              <v-checkbox
                label="Activo"
                class="mt-0"
                v-model="shippingMethod.active"
                ></v-checkbox>
            </div>

            <div
              style="gap: 12px"
              class="text-right d-flex justify-end"
              >
              <v-btn
                v-if="!(shippingMethod.name == 'Sendcargo')"
                tile
                color="error"
                @click="deleteShippingMethod"
                >
                Eliminar
              </v-btn>

              <v-btn
                tile
                color="primary"
                @click="editShippingMethod"
                >
                Guardar
              </v-btn>
            </div>
          </v-form>

          <v-snackbar
            :timeout="3000"
            :color="alert.type"
            v-model="alert.active"
            >
            <v-icon
              class="mr-3"
              >mdi-check-circle</v-icon> {{ alert.message }}
          </v-snackbar>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { ShippingMethod } from '@/graphql/queries/shipping_methods'
import { Edit, Delete } from '@/graphql/mutations/shipping_method'
import { Email, Required } from '@/utils/rules'
import { Chile } from '@/utils/regions'

export default {
  data () {
    return {
      valid: true,
      expand: false,
      shippingMethod: null,
      showSecret: false,
      emailRules: Email,
      requiredRules: Required,
      regions: Chile,
      alert: {
        active: false,
        message: '',
        type: 'success'
      }
    }
  },

  props: {
    id: {
      required: true,
      type: Number | String
    }
  },

  mounted () {
    this.fetchShippingMethod()
  },

  methods: {
    sendcargoPricingUrl () {
      window.open(process.env.VUE_APP_RAILS_URL + '/sendcargo_pricing')
    },

    editShippingMethod () {
      if (this.valid) this.valid = this.$refs.form.validate()

      if (this.valid) {
        this.$apollo.mutate({
          mutation: Edit,
          variables: {
            input: {
              id: this.id,
              attributes: this.shippingMethod
            }
          }
        }).then( res => {
          this.fetchShippingMethod()
          this.alert = {
            active: true,
            message: '¡Credenciales editadas con éxito!',
            type: 'success'
          }
        })
      }
    },

    deleteShippingMethod () {
      this.$apollo.mutate({
        mutation: Delete,
        variables: {
          input: {
            id: this.id,
          }
        }
      }).then( res => {
        this.$router.go()
      })
    },

    fetchShippingMethod () {
      this.$apollo.query({
        query: ShippingMethod,
        variables: {
          id: this.id
        }
      }).then( res => {
        this.shippingMethod = res.data.shippingMethod
        delete this.shippingMethod.__typename
      })
    }
  }
}
</script>
