import gql from 'graphql-tag'

export const Create = gql`
  mutation createShippingMethod($input: CreateShippingMethodInput!) {
    createShippingMethod(input: $input) {
      success
    }
  }
`

export const Edit = gql`
  mutation editShippingMethod($input: EditShippingMethodInput!) {
    editShippingMethod(input: $input) {
      success
    }
  }
`

export const Delete = gql`
  mutation deleteShippingMethod($input: DeleteShippingMethodInput!) {
    deleteShippingMethod(input: $input) {
      success
    }
  }
`
