import messages from '../i18n'
import VueI18n from 'vue-i18n'
import { LocaleService } from '../services/storageService'

const locale = LocaleService.getLocale()

function getMsg (code, params = {}) {
  let lcl = (Object.keys(messages).includes(locale)) ? locale : 'es'
  const i18n = new VueI18n({
    locale: lcl, // set locale
    messages, // set locale messages
  })

  return i18n.t('validation.' + code, params)
}

export const Email = [
  v => !!v || getMsg('is_required'),
  v => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || getMsg('must_be_a_valid_email')
  }
]

export const Password = [
  v => !!v || getMsg('is_required'),
  v => v.length >= 6 || getMsg('must_be_gte_to_6_chars'),
]

export const Username = [
  v => !!v || getMsg('is_required'),
  v => v.length >= 8 || getMsg('must_be_gte_to_8_chars'),
]

export const Required = [
  v => !!v || getMsg('is_required'),
]

export const IdNumber = [
  v => !!v || getMsg('is_required'),
//  v => ((v || '').length == 8 || (v || '').length == 7) || getMsg('must_be_bt_8_7'),
]

export const CardNumber = [
  v => !!v || getMsg('is_required'),
  v => ((v || '').length >= 15) || getMsg('must_be_gt_than_n', {number: 15}),
]

export const SingleDateYear = [
  v => !!v || getMsg('is_required'),
  v => ((v || '').length == 2) || getMsg('must_be_gt_than_n', {number: 2}),
  v => {
    let date = new Date()
    let currentYear = date.getFullYear().toString().substr(-2)
    return (v || '') >= currentYear || getMsg('invalid_year')
  }
]

export const SingleDateMonth = [
  v => !!v || getMsg('is_required'),
  v => ((v || '').length < 3) || getMsg('must_be_lt_n', {number: 2}),
]

export const SecurityCode = [
  v => !!v || getMsg('is_required'),
  v => ((v || '').length >= 3) || getMsg('must_be_gt_than_n', {number: 3}),
]

export const Phone = [
  v => !!v || getMsg('is_required'),
  v => ((v || '').length == 10 ) || getMsg('must_be_eq_n', {number: 10}),
]
